<template>
  <div class="page-auth">
    <div class="navbar-pad"></div>
    <div class="row container mx-auto align-self-stretch">
      <div
        class="col-12 col-lg-6 d-none d-lg-flex justify-content-center align-items-center"
      >
        <img
          src="@/assets/images/poster-default.jpg"
          class="h-25 w-auto rounded-circle shadow-sm"
        />
      </div>
      <div class="col-12 col-lg-6 py-5">
        <div class="h-100 d-flex justify-content-center align-items-center">
          <div class="auth-form w-100 bg-white shadow rounded">
            <p class="fs-6 fw-medium">Welcome back!</p>
            <p class="mb-5">Sign in to your account</p>
            <span class="text-danger small" v-if="error.identifier">
              <i class="fas fa-exclamation-circle mx-1" />
              Invalid email address
            </span>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                v-model="identifier"
                :class="{ 'is-invalid': error.identifier }"
                :disabled="isLoading"
                @keydown.enter="doSignIn()"
              />
              <label for="floatingInput">
                <i class="fas fa-at me-2 text-muted" />
                Email address
              </label>
            </div>
            <span class="text-danger small" v-if="error.password">
              <i class="fas fa-exclamation-circle mx-1" />
              Invalid password
            </span>
            <div class="form-floating mb-4">
              <input
                :type="showPasswordText ? 'text' : 'password'"
                class="form-control"
                id="floatingPassword"
                placeholder="Password"
                v-model="password"
                :class="{ 'is-invalid': error.password }"
                :disabled="isLoading"
                @keydown.enter="doSignIn()"
              />
              <label for="floatingPassword">
                <i class="fas fa-lock me-2 text-muted" />
                Password
              </label>
              <button
                class="btn btn-link position-absolute top-0 end-0 h-100 text-muted"
                @click="showPasswordText = !showPasswordText"
              >
                <i
                  class="far"
                  :class="showPasswordText ? 'fa-eye' : 'fa-eye-slash'"
                />
              </button>
            </div>
            <div
              class="mb-3 alert alert-danger text-dark small"
              v-if="showErrorMessage"
            >
              <p class="fw-medium">
                <i class="fas fa-exclamation-triangle me-2 text-danger" />
                Incorrect email or password
              </p>
              <p>
                The email or password you entered is incorrect. Please try again
                or contact support if you are unable to access your account.
              </p>
            </div>
            <button
              class="btn btn-primary btn-lg w-100 mb-4"
              @click="doSignIn()"
              :disabled="isLoading"
            >
              <small v-if="!isLoading">Sign In</small>
              <small v-else>
                <span class="spinner-border spinner-border-sm me-2"></span>
                Signing In
              </small>
            </button>
            <p class="mb-0 fw-medium small">Don't have an account?</p>
            <router-link
              :to="{ name: 'SignUp', query: { returnUrl: returnUrl } }"
              class="text-decoration-none text-secondary small"
            >
              Create an account here. It's free!
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isLoading: false,
      returnUrl: null,
      identifier: "",
      password: "",
      error: {
        identifier: false,
        password: false,
      },
      showPasswordText: false,
      showErrorMessage: false,
    };
  },
  watch: {
    identifier: {
      handler() {
        if (this.identifier) {
          this.identifier = this.identifier.replace(/\s+/g, "");
        }
      },
      immediate: true,
    },
  },
  methods: {
    async doSignIn() {
      this.isLoading = true;
      this.showErrorMessage = false;

      setTimeout(async () => {
        const emailValidation = this.Helper.validateEmail(this.identifier);
        const passwordValidation = this.Helper.validatePassword(this.password);

        this.error.identifier = !emailValidation;
        this.error.password = !passwordValidation;

        if (!emailValidation || !passwordValidation) {
          this.isLoading = false;

          return;
        } else {
          try {
            const { data } = await this.API.post("auth/local", {
              identifier: this.identifier,
              password: this.password,
            });

            if (!data) {
              this.showErrorMessage = true;
            } else {
              window.scrollTo(0, 0);

              localStorage.setItem("token", data.jwt);

              this.Helper.validateToken();

              let returnPath =
                data.user.role && data.user.role.name == "Admin"
                  ? "/admin"
                  : data.user.isCRT
                  ? "/crt"
                  : "/my-account";

              if (this.returnUrl) {
                returnPath = this.returnUrl;
              }

              this.$router.push({
                path: returnPath,
              });
            }
          } catch (err) {
            this.showErrorMessage = true;
          } finally {
            this.isLoading = false;
          }
        }
      }, 300);
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.returnUrl) {
      this.returnUrl = this.$route.query.returnUrl;
    }
  },
};
</script>